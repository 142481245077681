
.message {
    width: 500px;
    margin: 0 auto;
    background-color: #F8E0E6;
    border: 2px solid #FFB6C1;
    border-radius: 5px;
    padding: 20px;
    font-family: Arial, sans-serif;
    font-size: 18px;
    line-height: 1.5;
  }
  .ascii-art {
    font-family: monospace;
    white-space: pre;
}
  .signature {

    font-family: Arial, sans-serif;
    font-size: 18px;
    text-align: left;
  }

  .message h1 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #FF0080;
  }

  .message p {
    margin: 0;
    color: #663399;
  }
